/* ------------------------------------------------------------------
   Template Default Button, Input Styles - Value Change with Your's Need
 -------------------------------------------------------------------- */
.btnd {
    line-height: 1;
    display: inline-block;
    @include transition(0.4s);
    text-transform: capitalize;
}

.theme-btn {
    color: $black;
    @extend .btnd;
    padding: 24px 36px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
    background-color: $theme-dark;
    border: 2px solid $theme-dark;
    transition: all 0.3s ease-in-out;

    @media (max-width: 991px) {
        padding: 16px 30px;
        font-weight: 400;
        border-radius: 5px;
    }

    @media (max-width: 767px) {
        padding: 14px 28px;
        font-size: 15px;
    }

    &.theme-2 {
        background-color: $theme-color-2;
        border: 2px solid $theme-color-2;
        color: #fff;
        border-radius: 50px;
        padding: 22px 40px;

        &:hover {
            background-color: $second-color;
            border: 2px solid $second-color;
        }

        &.white {
            background: #fff;
            box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
            color: $second-color;
            border-color: #fff;

            &:hover {
                background-color: $second-color;
                color: #fff;
                border-color: $second-color;
            }
        }
    }

    &.theme-3 {
        -webkit-clip-path: polygon(8% 0%, 100% 0%, 92% 100%, 0% 100%);
        clip-path: polygon(8% 0%, 100% 0%, 92% 100%, 0% 100%);
        border-radius: 0px;
        padding: 22px 50px;
    }

    @media (max-width: 580px) {
        padding: 16px 35px;
    }

    @media (max-width: 480px) {
        padding: 15px 30px;
        font-size: 15px;
    }

    i {
        margin-left: 15px;
    }

    &.no-fil {
        background-color: transparent;
        border-color: rgba($color: #fff, $alpha: 0.2);
        box-shadow: inherit;
        margin-left: 15px;

        @media (max-width: 500px) {
            margin-left: 0;
        }

        &:hover {
            background-color: $theme-color;
            border: 2px solid $theme-color;
        }
    }

    &.black {
        background-color: #000;
        border-color: #000;
        box-shadow: inherit;
        color: $white;

        &:hover {
            background-color: $theme-color;
            border: 2px solid $theme-color;
        }
    }

    &.minimal-btn {
        color: #fff;
        border: 2px solid #ebebeb;
        background: transparent;
        box-shadow: inherit;

        &:hover {
            background-color: $theme-color;
            border: 2px solid $theme-color;
            box-shadow: inherit;
            color: $white;
        }
    }

    &:hover {
        background-color: #000;
        border: 2px solid #000;
        box-shadow: inherit;
        color: $white;
    }
}

.video-btn {
    @extend .btnd;

    font-size: 16px;
    font-weight: 700;
    color: #726ceb;

    i {
        margin-right: 10px;
    }
}

.video-play-btn {
    .play-video {
        border-radius: 50%;
        width: 120px;
        height: 120px;
        background-color: #f86048;
        color: $white;
        line-height: 120px;
        transition: 0.3s;
        text-align: center;
        animation: playVideo infinite 3.5s;
        display: inline-block;
        margin: 0px auto;

        @media (max-width: 767px) {
            width: 80px;
            height: 80px;
            line-height: 80px;
        }

        &:hover {
            color: $theme-color;
            box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
        }
    }

    .play-text {
        font-weight: 700;
    }
}

.cat-btn {
    font-size: 16px;
    line-height: 1;
    padding: 20px 30px;
    font-weight: 700;
    border-style: solid;
    border-width: 2px;
    border-color: rgba($color: #fff, $alpha: 0.2);
    transition: all 0.3s ease-in-out;
    background: transparent;
    display: inline-block;
    margin-right: 10px;
    margin-top: 30px;

    i {
        margin-right: 10px;
    }

    &.color {
        background-color: #00baa3;
        border-color: #00baa3;
        color: #fff;
    }

    &:hover {
        background-color: #00baa3;
        border-color: #00baa3;
        border-style: solid;
        border-width: 2px;
    }
}
