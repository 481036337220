* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html {
    height: 100%;
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  body {
    height: 100%;
  }
  
  body,
  div,
  span,
  img,
  ol,
  ul,
  li,
  a,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  option,
  legend,
  label,
  table,
  th,
  td,
  tr,
  article,
  aside,
  caption,
  figure,
  footer,
  header,
  hgroup,
  mark,
  nav,
  section,
  time,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
  }
  
  img {
    max-width: 100%;
  }
  
  a {
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      outline: none;
      border: 0;
    }
  }
  
  input,
  textarea,
  select {
    &:focus {
      outline: none;
    }
  }
  
  input {
    &::-moz-focus-inner {
      border: 0;
      display: none;
    }
  
    &::-webkit-focus-inner {
      border: 0;
      display: none;
    }
  
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 1000px white inset !important;
    }
  }
  
  ::placeholder {
    color: #666;
    opacity: 1;
  }
  
  :-ms-input-placeholder {
    color: #666;
  }
  
  ::-ms-input-placeholder {
    color: #666;
  }
  
  table {
    border-collapse: collapse;
  }
  
  ul,
  li {
    list-style-type: none;
    position: relative;
  }
  
  figure {
    margin: 0;
  }
  
  form {
    position: relative;
  }
  
  .form-control:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    a {
      font-size: inherit;
      color: inherit;
      font-weight: inherit;
      line-height: inherit;
    }
  
    &:hover a {
      font-size: inherit;
      color: inherit;
      font-weight: inherit;
    }
  }
  
  hr {
    margin: 0;
    padding: 0;
    border-color: #000;
  }
  
  a,
  button {
    position: relative;
  
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
    }
  }
  
  input,
  textarea,
  button {
    border: 0;
  }
  
  .container {
    width: 100%;
  
    @media (min-width: 1365px) {
      max-width: 1320px !important;
    }
  
    @media (max-width: 1364px) {
      max-width: inherit !important;
      width: inherit;
    }
  
    @media (max-width: 1200px) {
      width: 100%;
    }
  }