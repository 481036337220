/* detailsCarousel.scss */
.details-carousel-img-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; /* Add this line to set the background color */
  }
  
  .details-carousel-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  