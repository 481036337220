.promo-box-items {
    .container {
        margin-top: -200px;
        z-index: 2;
        position: relative;

        @media (max-width: 991px) {
            margin-top: -100px;
        }
    }

    h2 {
        @media (max-width: 1191px) {
            font-size: 26px;
            line-height: 36px;
        }
    }

    .subscribe-promo-box {
        @media (max-width: 991px) {
            margin-top: 30px;
        }
    }

    .checkout-promo-box {
        @media (max-width: 767px) {
            margin-top: 30px;
        }
    }
}

.support-promo-box {
    .promo-bg {
        height: 210px;
        width: 100%;
    }

    .promo-details {
        padding: 60px 30px;
        background: #00baa3;
        position: relative;
        z-index: 1;

        @media (min-width: 991px) and (max-width: 1199px) {
            padding: 50px 19px;
        }

        @media (max-width: 500px) {
            padding: 50px 30px;
        }

        &::before {
            position: absolute;
            top: 36%;
            right: 20px;
            content: "\f21e";
            font-family: $fa;
            font-size: 110px;
            font-weight: 300;
            opacity: 0.07;
            color: #fff;
            z-index: -1;
        }
    }
}

.checkout-promo-box {
    background-color: #d55342;
    padding: 60px 30px;

    @media (min-width: 991px) and (max-width: 1199px) {
        padding: 50px 20px;
    }

    @media (max-width: 500px) {
        padding: 50px 30px;
    }

    .icon {
        margin-bottom: 40px;
    }

    a {
        margin-top: 30px;
    }
}

.subscribe-promo-box {
    background-color: #efc94c;
    padding: 60px 50px;

    @media (min-width: 991px) and (max-width: 1199px) {
        padding: 50px 20px;
    }

    @media (max-width: 500px) {
        padding: 50px 30px;
    }

    .icon {
        margin-bottom: 40px;
    }
    form {
        margin-top: 30px;
        position: relative;

        input {
            border: 0px;
            position: relative;
            height: 60px;
            line-height: 60px;
            border-radius: 30px;
            border: 2px solid #f5df94;
            background: transparent;
            display: block;
            font-size: 14px;
            width: 100%;
            color: #fff;
            padding-left: 30px;
            &::placeholder {
                color: #fff;
            }
        }

        button {
            top: 0;
            right: 0;
            width: 60px;
            height: 60px;
            font-size: 16px;
            line-height: 60px;
            position: absolute;
            text-align: center;
            border: none;
            border-radius: 30px;
            color: #fff;
            background-color: $theme-color;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: $green;
            }
        }
    }
}
