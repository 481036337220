.single-pricing-table {
    position: relative;
    z-index: 1;
    padding: 70px 60px;
    margin-top: 50px;

    @media (max-width: 767px) {
        padding: 50px 40px;
    }

    &:hover,
    &.active {
        &::before {
            opacity: 0.9;
            background: #000;
        }

        h3 {
            color: #fff;
        }

        li {
            color: #c8c8c8;
        }

        .theme-btn {
            background-color: $theme-color;
            border-color: $theme-color;
        }
    }

    &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        content: "";
        background-color: #fff;
        z-index: -1;
        transition: all 0.3s ease-in-out;
    }

    .price {
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
        color: $theme-color;

        span {
            font-size: 100px;

            @media (max-width: 767px) {
                font-size: 80px;
            }
        }
    }

    .package-name {
        margin-top: 15px;

        h3 {
            text-transform: capitalize;
        }

        span {
            text-transform: capitalize;
        }
    }

    ul {
        margin: 40px 0px;
        li {
            position: relative;
            padding-left: 25px;
            margin-bottom: 7px;
            display: block;

            // &::before {
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     content: "\f00c";
            //     font-family: $fa;
            //     font-weight: 400;
            //     color: $theme-color;
            // }
        }
    }

    .package-btn {
        .theme-btn {
            font-size: 16px;
            font-weight: 700;
            line-height: 1;
            width: 100%;
            text-align: center;
        }
    }
}

.single-package-plan {
    padding: 50px 55px 60px;
    background-color: #fff;
    margin-top: 30px;
    position: relative;
    transition: all 0.3s ease-in-out;

    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: $theme-color-2;
        content: "";
        transition: all 0.3s ease-in-out;
        opacity: 0;
        visibility: hidden;
    }

    &:hover,
    &.active {
        &::before {
            height: 4px;
            opacity: 1;
            visibility: visible;
        }

        li {
            &::before {
                color: $theme-color-2;
            }
        }
    }

    .plan-name {
        margin-bottom: 30px;
        h3 {
            margin-bottom: 10px;
        }

        .price {
            margin-right: 20px;

            h1 {
                font-size: 70px;
                line-height: 1;
            }
        }

        .plan-time {
            font-size: 15px;
            line-height: 26px;
        }
    }

    .package-features {
        ul {
            li {
                position: relative;
                display: block;
                margin-bottom: 15px;
                font-size: 16px;

                &::before {
                    position: absolute;
                    right: 0;
                    content: "\f00c";
                    font-family: $fa;
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }

    .but-btn {
        margin-top: 40px;

        .theme-btn {
            display: block;
            text-align: center;

            i {
                margin-left: 15px;
            }
        }
    }
}

.pricing-features {
    li {
        display: flex !important;
        justify-content: flex-start;
        margin-left: -25px;
    }
    .icon {
        color: #1a98ff;
        margin-right: 8px;
        margin-top: 5px;
        font-size: 20px;
    }
}
